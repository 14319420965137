export interface ProjectInfo {
  name: string
  title: string
  description: string
  url: string
  tags: string[]
  active?: boolean
  product?: boolean
}

export const projects: ProjectInfo[] = [
  {
    product: true,
    name: 'receipts',
    title: 'Receipts App',
    description: 'Smart document management for finances',
    url: 'https://www.receipts-app.com/',
    tags: ['app', 'finance', 'documents', 'apple', 'receipts'],
  },
  {
    product: true,
    name: 'pdfify',
    title: 'PDFify App',
    description: 'Create searchable PDF and save disk space',
    url: 'https://pdfify.app/',
    tags: ['app', 'pdf', 'documents', 'apple', 'pdfify'],
  },
  {
    name: 'zeed',
    title: 'Zeed',
    description: 'Simple Typescript foundation library',
    url: 'https://github.com/holtwick/zeed/',
    tags: ['opensource', 'javascript', 'web', 'zeed'],
  },
  {
    name: 'zeed-dom',
    title: 'Zeed-DOM',
    description: 'Lightweight offline DOM and parser',
    url: 'https://github.com/holtwick/zeed-dom/',
    tags: ['opensource', 'javascript', 'zeed'],
  },
  {
    product: true,
    name: 'briefing',
    title: 'Briefing',
    description: 'Secure direct video group chat ',
    url: 'https://brie.fi/ng',
    tags: ['opensource', 'javascript', 'webrtc', 'web', 'briefing'],
  },
  {
    active: false,
    name: 'peer-school',
    title: 'Peer.School',
    description: 'Simple P2P teaching platform',
    url: 'https://peer.school',
    tags: ['opensource', 'javascript', 'webrtc', 'school', 'web', 'briefing'],
  },
  {
    name: 'zerva',
    title: 'Zerva',
    description: 'Simple event driven services',
    url: 'https://github.com/holtwick/zerva',
    tags: ['opensource', 'javascript', 'zeed'],
  },
  {
    active: false,
    name: 'hostic',
    title: 'Hostic',
    description: 'Static website generator',
    url: 'https://github.com/holtwick/hostic',
    tags: ['opensource', 'javascript', 'web', 'zeed'],
  },
  {
    active: false,
    name: 'twindy',
    title: 'Twindy',
    description: 'CSS framework in Stylus',
    url: 'https://github.com/holtwick/twindy',
    tags: ['opensource', 'css', 'stylus', 'web', 'zeed', 'css'],
  },
  {
    active: false,
    name: 'twindy-headless',
    title: 'Twindy-Headless',
    description: 'Vue3 UI components for Twindy',
    url: 'https://github.com/holtwick/twindy-headless',
    tags: ['opensource', 'twindy', 'vue', 'web', 'zeed'],
  },
  {
    name: 'Oui',
    title: 'Oui',
    description: 'CSS & Vue UI framework using Stylus',
    url: 'https://github.com/holtwick/oui',
    tags: ['opensource', 'vue', 'web', 'css', 'zeed'],
  },
]
