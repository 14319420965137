<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { jsonStringifySorted } from 'zeed'
import { OuiMobileActivator, OuiTooltipActivator } from 'oui-kit'
import AppFooter from '@/components/app-footer.vue'
import AppHeader from '@/components/app-header.vue'
import { useAppHeadHoltwick } from '@/lib/use-app-head-holtwick'
import { useExternalLinks } from '@/lib/use-external-links'

useAppHeadHoltwick()
useExternalLinks()

const json = [{
  '@context': 'http://schema.org',
  '@type': 'Organization',
  'name': 'Dirk Holtwick',
  'url': 'https://holtwick.de',
  'sameAs': [
    'https://twitter.com/holtwick',
    'https://x.com/holtwick',
    'https://mastodon.social/@holtwick',
    'https://github.com/holtwick',
    'https://www.linkedin.com/in/holtwick/',
    'https://www.xing.com/profile/Dirk_Holtwick/cv',
    'https://www.facebook.com/dirk.holtwick',
  ],
}]

useHead({
  script: json.map(data => ({
    type: 'application/ld+json',
    textContent: jsonStringifySorted(data),
  })),
})
</script>

<template>
  <template v-if="$route.meta.pure">
    <router-view />
  </template>
  <template v-else>
    <AppHeader
      rounded
    />
    <main class="app-content">
      <Suspense>
        <router-view />
      </Suspense>
    </main>
  </template>
  <AppFooter
    support
    imprint
    privacy
    github
    mastodon
    rss
  />
  <ClientOnly>
    <OuiTooltipActivator />
    <OuiMobileActivator />
  </ClientOnly>
</template>
